import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import MyClientsList from './MyClientsList';
import ClientSchedule from './ClientSchedule';
import ClientDashboard from './ClientDashboard';

import ScheduleProgram from './ScheduleProgram';
import AddExercisesScreen from '../schedules/AddExercisesScreen';
import ScheduleWorkoutScreen from '../schedules/ScheduleWorkoutScreen';
import CustomizeProgram from '../schedules/CustomizeProgram';
import CustomizeWorkout from '../schedules/CustomizeWorkout';

import { getWorkoutSections } from '../../reducers/workoutsReducer';
import {ClientsProvider} from '../../contexts/ClientsContext';
import { WorkoutsProvider } from '../../contexts/WorkoutsContext';

import {addPossessiveApostrophe, getSelectedDate} from '../../util/utilFunctions';

export default function ClientScheduleNavigator () {
  const dispatch = useDispatch();
  const location = useLocation();
  const [pathMap, setPathMap] = useState({});

  const [selectedDate, setSelectedDate] = useState(getSelectedDate(location?.state?.selectedDate ?? null));
  // The month year date subheader e.g. < March 2021 >
  const [monthViewDate, setMonthViewDate] = useState(getSelectedDate(location?.state?.selectedDate ?? null));
  const selectedGroup = useSelector((state) => state.selectedGroup?.group ?? {});
  const selectedClient = useSelector(state => state.selectedClient?.client ?? {});
  const defaultSections = useSelector(state => state.workouts.defaultSections);
  useEffect(() => {
    if (defaultSections.length === 0) {
      dispatch(getWorkoutSections());
    }
  }, [dispatch, defaultSections]);

  const sectionIds = defaultSections.map(section => section.id);

  // eslint-disable-next-line camelcase
  const clientFirstName = selectedClient?.first_name ?? '';

  // Updates the pathMap whenever trainer changes their selected client
  useEffect(() => {
    if (clientFirstName) {
      setPathMap(generateTrailMap(clientFirstName));
    }
  }, [clientFirstName]);

  // Updates the selected date to the starting scheduled date made by the trainer
  useEffect(() => {
    if(!!location?.state?.selectedDate) {
      const startScheduleDate = location.state.selectedDate;
      setSelectedDate(getSelectedDate(startScheduleDate));
      setMonthViewDate(getSelectedDate(startScheduleDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.selectedDate]);

  return (
    <Switch>
      <Route path='/clients/my-clients/:clientFirstName/dashboard/calendar/schedule-exercises-next'>
        <AddExercisesScreen
          clientFirstName={clientFirstName}
          pathMap={pathMap}
          selectedDate={selectedDate}
          isScheduling={false}
        />
      </Route>
      <Route path='/clients/my-clients/:clientFirstName/dashboard/calendar/schedule-exercises-schedule'>
        <AddExercisesScreen
          selectedGroup={selectedGroup}
          clientFirstName={clientFirstName}
          pathMap={pathMap}
          selectedDate={selectedDate}
          isScheduling
        />
      </Route>
      <Route exact path='/clients/my-clients/:clientFirstName/dashboard/calendar'>
        <ClientSchedule
          selectedClient={selectedClient}
          pathMap={pathMap}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          monthViewDate={monthViewDate}
          setMonthViewDate={setMonthViewDate}
        />
      </Route>
      <Route exact path='/clients/my-clients'>
        <ClientsProvider>
          <MyClientsList selectedClient={selectedClient} />
        </ClientsProvider>
      </Route>
      <Route exact path='/clients/my-clients/:clientFirstName/dashboard'>
        <ClientDashboard
          selectedClient={selectedClient}
          pathMap={pathMap}
        />
      </Route>
      <Route path='/clients/my-clients/:clientFirstName/dashboard/calendar/schedule-programs'>
        <ScheduleProgram
          pathMap={pathMap}
          sectionIds={sectionIds}
          selectedDate={selectedDate}
        />
      </Route>
      <Route path='/clients/my-clients/:clientFirstName/dashboard/calendar/schedule-workouts'>
        <WorkoutsProvider>
          <ScheduleWorkoutScreen
            pathMap={pathMap}
            clientFirstName={clientFirstName}
            selectedDate={selectedDate}
          />
        </WorkoutsProvider>
      </Route>
      <Route path='/clients/my-clients/:clientFirstName/dashboard/calendar/customize-program'>
        <CustomizeProgram
          pathMap={pathMap}
          sectionIds={sectionIds}
        />
      </Route>
      <Route path='/clients/my-clients/:clientFirstName/dashboard/calendar/customize-workout'>
        <CustomizeWorkout
          pathMap={pathMap}
          sectionIds={sectionIds}
          client={selectedClient}
        />
      </Route>
    </Switch>
  );
}

// HELPER FUNCTION(S) //
// Represents a bread-trail of possible paths the trainer can navigate to
// while creating or updating a schedule for their client.
// Add additional paths here as needed in order for <ClientBreadcrumbs />
// component to properly render the breadcrumb trail
function generateTrailMap (clientFirstName) {
  const nameLowercase = clientFirstName.toLowerCase();
  return {
    '/clients/my-clients': 'Clients',
    [`/clients/my-clients/${nameLowercase}/dashboard`]: `${addPossessiveApostrophe(clientFirstName)} Dashboard`,
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar`]: `${addPossessiveApostrophe(clientFirstName)} Schedule`,
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/schedule-programs/my-programs`]: 'Assign Program',
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/schedule-programs/nasm-programs`]: 'Assign Program',
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/schedule-workouts/my-workouts`]: 'Assign Workout',
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/schedule-workouts/nasm-workouts`]: 'Assign Workout',
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/schedule-exercises`]: 'Assign Exercises',
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/edit-workout`]: 'Edit Workout',
    [`/clients/my-clients/${nameLowercase}/dashboard/calendar/edit-workout/workout-details`]: 'Workout Details',
  };
}
