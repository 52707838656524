import ReactPlayer from "react-player";
import React from "react";

const ExerciseMediaContainer = ({ videoUrl, imageUrl, placeholderImg, didErrorOccur, onCanPlay, onError }) => {
  const type = didErrorOccur ? 'img' : (videoUrl ? 'video' : 'img');
  if (type === 'img') {
    return (
      <img
        width={'100%'}
        height={'100%'}
        alt=""
        src={imageUrl || placeholderImg}
      />
    );
  }

  return (
    <ReactPlayer
      controls
      playing
      light={<img src={imageUrl || placeholderImg} width={'100%'} height={'100%'} alt="" />}
      width={'100%'}
      height={'100%'}
      style={{ position: 'relative' }}
      url={videoUrl}
      onCanPlay={onCanPlay}
      onError={onError}
      config={{
        file: {
          attributes: {disablePictureInPicture: true},
        },
      }}
    />
  );
};

export default ExerciseMediaContainer;
