import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory  } from 'react-router-dom';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExerciseMediaContainer from "../ExerciseMediaContainer";

import { colors } from '../../styles';
import downArrowIcon from '../../resources/down-arrow-small.svg';
import placeholderImg from '../../resources/image-exercise-placeholder@3x.png';

import ExerciseDetailsSkeleton from '../LoadingStates/ExerciseDetailsSkeleton';
import ButtonsContainer from './ButtonsContainer';

import useFetchExerciseDetails from '../../hooks/exercises/FetchExerciseDetails';
import { addExercises, addExerciseToSection, editExercise } from '../../reducers/selectedWorkoutReducer';
import { ROLES, TEMPO_MAPPINGS } from '../../constants';
import ProgressionsRegressionsList from '../ProgressionsRegressionsList';
import { programContexts } from '../../reducers/programContextReducer';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

const ExerciseLabel = withStyles({
  root: {
    color: colors.steel,
    fontSize: '17px',
    lineHeight: 1.18,
    textAlign: 'center',
  },
})(Typography);

const ExerciseValue = withStyles({
  root: {
    fontSize: '17px',
    fontWeight: 900,
    textAlign: 'center',
  },
})(Typography);

const ExerciseTitle = withStyles({
  root: {
    fontSize: '24px',
    fontWeight: 900,
    lineHeight: 1.63,
    paddingTop: '12px',
  },
})(Typography);

const useStyles = makeStyles({
  removeTopBorder: {
    '&:before': {
      height: 0,
    },
  },
  detailsView: {
    height: 720,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  exerciseDetails: {
    padding: '0px 20px',
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    paddingTop: '26px',
  },
  description: {
    color: colors.steel,
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 1.94,
  },
  accordionStyle: {
    boxShadow: 'none',
    width: '100%',
    paddingBottom: '20px',
  },
  accordionActionsStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
  },
  accordionSummaryStyle: {
    padding: 0,
  },
  accordionSummaryContentStyle: {
    margin: 0,
  },
  accordionDetailsStyle: {
    paddingTop: 0,
  },
  progregCont: {
    paddingTop: 20,
  },
  swapCont: {
    padding: 20,
  },
  buttonRoot: {
    background: 'transparent',
    borderRadius: 30,
    border: `2px solid ${colors.selected_highlight_copy_6}`,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
  buttonLabel: {
    textTransform: 'capitalize',
    fontSize: 17,
    fontWeight: '800',
    fontStyle: 'Avenir',
    color: colors.steel,
  },
  switchDes: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'DM Sans',
    color: colors.steel,
    margin: '5px 15% 10px',
  },
});

function ExerciseContent (props) {
  const classes = useStyles();
  const {
    exerciseDetails,
    exeOrCompoundExe,
    onToggleCollapse,
    viewExerciseDetailsLink,
    viewSwapPageLink,
    selectedWorkout,
    revertToOriginal,
    exerciseKey,
    showProgressionsRegressions,
    location,
  } = props;

  const {
    id,
    name: exerciseName,
    description,
    video_url: videoUrl,
    image_url: imageUrl,

  } = (exerciseDetails || {});

  const history = useHistory();
  const [showConfirmRevertDialog, setShowConfirmRevertDialog] = useState(false);
  const [didMediaErrorOccur, setDidMediaErrorOccur] = useState(false);
  const { currentUser, programContext } = useSelector(state => state);
  const isTrainer = currentUser.role === ROLES.TRAINER;
  const isSwapAllowed = programContext.context === programContexts.SCHEDULING && isTrainer;
  const hasProgressions = exerciseDetails?.progressions_regressions?.length;
  const isExerciseSwapped = exerciseDetails?.swapped_original_exercise;

  useEffect(() => {
    // reset state when new exerciseDetails is loaded
    setDidMediaErrorOccur(false);
  }, [id]);

  const accordionDetailsRef = useRef();
  function onChange (e, expanded) {
    onToggleCollapse(e, expanded, accordionDetailsRef);
  }

  function onError (e) {
    setDidMediaErrorOccur(true);
    console.error('Failed to load media resource: ', e.target.src);
    e.target.src = placeholderImg;
  }

  function onCanPlay () {
    setDidMediaErrorOccur(false);
  }

  const handleViewSwapPageLink = () => {
    history.push({
      pathname: location.pathname,
      search: viewSwapPageLink(id),
    });
  };

  const onCloseConfirmationDialog = () => {
    setShowConfirmRevertDialog(false);
  };

  const handleRevert = () => {
    setShowConfirmRevertDialog(true);
  };

  // defaults to returning exercise template variables if there is no selected workout or the
  // exercise template associated with the exercise from the workout does not exist
  const { reps, sets, tempo, rest } = getExerciseDetailsFromWorkout(exerciseDetails, selectedWorkout);
  return (
    <Box className={classes.detailsView}>
      <Box className={classes.exerciseDetails}>
        <ExerciseTitle>
          {exerciseName || 'No Exercise Name was set'}
        </ExerciseTitle>
        <Accordion
          onChange={onChange}
          className={classes.accordionStyle}
          classes={{ root: classes.removeTopBorder }}
        >
          <AccordionActions
            disableSpacing
            className={classes.accordionActionsStyle}
          >
            <AccordionSummary
              expandIcon={<img alt='collapse-arrow' src={downArrowIcon} />}
              className={classes.accordionSummaryStyle}
              classes={{ content: classes.accordionSummaryContentStyle }}
            >
              <Typography
                className={classes.description}
              >Description
              </Typography>
            </AccordionSummary>
          </AccordionActions>
          <AccordionDetails
            ref={accordionDetailsRef}
            className={classes.accordionDetailsStyle}
          >
            <Typography>
              {description || 'No description.'}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <ExerciseMediaContainer
          videoUrl={videoUrl}
          imageUrl={imageUrl}
          placeholderImg={placeholderImg}
          onError={onError}
          onCanPlay={onCanPlay}
          didErrorOccur={didMediaErrorOccur}
        />
        <Box className={classes.detailsContainer}>
          <Box>
            <ExerciseLabel>Reps</ExerciseLabel>
            <ExerciseValue>{Number.parseInt(reps, 10) ? reps : 'N/A'}</ExerciseValue>
          </Box>
          <Box>
            <ExerciseLabel>Sets</ExerciseLabel>
            <ExerciseValue>{Number.parseInt(sets, 10) ? sets : 'N/A'}</ExerciseValue>
          </Box>
          <Box>
            <ExerciseLabel>Tempo</ExerciseLabel>
            <ExerciseValue>
              {
                tempo && TEMPO_MAPPINGS[tempo.toString()]
                  ? TEMPO_MAPPINGS[tempo.toString()] : 'N/A'
              }
            </ExerciseValue>
          </Box>
          <Box>
            <ExerciseLabel>Rest</ExerciseLabel>
            <ExerciseValue>{Number.isNaN(Number.parseInt(rest, 10)) ? 'N/A' : `${rest}s`}</ExerciseValue>
          </Box>
        </Box>
      </Box>
      <Box className={classes.progregCont}>
        {(isSwapAllowed && hasProgressions && showProgressionsRegressions) ? (
          <Box className={classes.swapCont}>
            <Button
              fullWidth={true}
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                if (!isExerciseSwapped) {
                  handleViewSwapPageLink();
                } else {
                  handleRevert();
                }
              }}
            >
              {!isExerciseSwapped ? 'Swap Exercise' : 'Revert Exercise'}
            </Button>
            <div className={classes.switchDes}>
              Switch out the current exercise for either a progression or regression exercise
            </div>
          </Box>
        ) : null}
        {(isTrainer && showProgressionsRegressions) ? (
          <ProgressionsRegressionsList
            exerciseDetails={exerciseDetails}
            exerciseKey={exerciseKey}
            exeOrCompoundExe={exeOrCompoundExe}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
          />
        ) : null}
      </Box>
      <ConfirmDialog
        title='Are you sure you want to revert to the original exercise?'
        actionButtonTitle='Yes, Revert'
        actionButtonWidth={120}
        open={showConfirmRevertDialog}
        onClose={onCloseConfirmationDialog}
        handleConfirmAction={() => {
          onCloseConfirmationDialog();
          revertToOriginal();
        }}
      />
    </Box>
  );
}

export default function ExerciseDetails (props) {
  const {
    exerciseId,
    exerciseKey: exerciseKeyToUse,
    isAutoSaveEnabled,
    isScheduling,
    isRescheduling,

    // Callback functions
    backLink,
    onToggleCollapse,
    resetSelection,
    viewExerciseDetailsLink,
    viewSwapPageLink,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const { isDataLoaded, exerciseDetails, error } = useFetchExerciseDetails(exerciseId);
  const { selectedWorkout } = useSelector(state => state);
  const searchParams = new URLSearchParams(location.search);
  const showProgReg = searchParams.get('showProgressionsRegressions') === 'false';
  const [showProgressionsRegressions, setShowProgressionsRegressions] = useState(true);

  let exerciseKey = exerciseKeyToUse;
  if (!showProgressionsRegressions) {
    exerciseKey = null;
  }

  useEffect(() => {
    setShowProgressionsRegressions(!showProgReg);
  }, [showProgReg]);

  if (!exerciseDetails) {
    return null;
  }

  let exerciseData = exerciseDetails;
  let exeOrCompoundExe = {};

  if (isScheduling || isRescheduling) {
    exeOrCompoundExe = Object.values(selectedWorkout?.entities?.exercises)?.find(
      (ex) => ex.key === exerciseKey,
    );
    if (!exeOrCompoundExe) {
      exeOrCompoundExe = exerciseDetails;
    }
    if (exeOrCompoundExe?.compound_routine_exercises?.length) {
      exerciseData = exeOrCompoundExe.compound_routine_exercises?.find(
        (ex) => {
          if (ex.id === exerciseDetails?.id) {
            return ex;
          }
          return null;
        },
      );
      if (!exerciseData) {
        exerciseData = exeOrCompoundExe.compound_routine_exercises.find(
          (ex) => {
            if (
              ex.swapped_original_exercise?.compound_routine_exercises?.some(
                (ex1) => ex1.id === exerciseDetails?.id,
              )
            ) {
              return ex;
            }
            return null;
          },
        );
      }
    } else {
      exerciseData = exeOrCompoundExe;
    }
  }

  const revertToOriginal = () => {
    const originalExercise = {
      ...exerciseData.swapped_original_exercise,
    };
    let originalExerciseData = originalExercise;
    if (
      exerciseData.original_exercise_id
      && exerciseData.swapped_original_exercise?.compound_routine_exercises?.length
    ) {
      originalExerciseData = exerciseData.swapped_original_exercise?.compound_routine_exercises?.find(
        (ex) => ex.id === exerciseData.original_exercise_id,
      );
    }
    exerciseData = originalExerciseData;
    dispatch(editExercise(originalExercise));
  };

  function onAddExercise () {
    if (isAutoSaveEnabled) {
      dispatch(addExercises({
        exercises: [exerciseDetails],
        // defaults to Warm-Up section if sectionId field is not supplied
      }));
    } else {
      dispatch(addExerciseToSection({
        exercise: exerciseDetails,
      }));
    }
  }

  return (
    <>
      {isDataLoaded && !error &&
        <>
          <ButtonsContainer
            backLink={backLink}
            resetSelection={resetSelection}
            onAddExercise={onAddExercise}
            isScheduling={isScheduling}
            isRescheduling={isRescheduling}
            hideAddBtn={props.hideAddBtn}
          />
          <ExerciseContent
            exerciseDetails={exerciseData}
            exeOrCompoundExe={exeOrCompoundExe}
            onToggleCollapse={onToggleCollapse}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
            viewSwapPageLink={viewSwapPageLink}
            selectedWorkout={selectedWorkout}
            revertToOriginal={revertToOriginal}
            exerciseKey={exerciseKey}
            showProgressionsRegressions={showProgressionsRegressions}
            location={location}
          />
        </>}
      {!isDataLoaded && <ExerciseDetailsSkeleton />}
    </>
  );
}

// HELPER FUNCTION(S) //
function getExerciseDetailsFromWorkout (exerciseTemplateDetails, selectedWorkout) {
  if (!selectedWorkout && !selectedWorkout.entities && !selectedWorkout.entities.exercises) {
    return {};
  }

  if (!exerciseTemplateDetails?.id) {
    return {};
  }

  const exerciseFromWorkout = Object.values(selectedWorkout.entities.exercises)
    .find(e => e.id === exerciseTemplateDetails?.id);

  if (exerciseFromWorkout) {
    return {
      reps: exerciseFromWorkout.reps,
      sets: exerciseFromWorkout.sets,
      tempo: exerciseFromWorkout.tempo,
      rest: exerciseFromWorkout.rest,
    };
  }

  return {
    reps: exerciseTemplateDetails.reps,
    sets: exerciseTemplateDetails.sets,
    tempo: exerciseTemplateDetails.tempo,
    rest: exerciseTemplateDetails.rest,

  };
}
